<template>
  <el-dialog
    title=""
    custom-class="mail-list-dialog"
    append-to-body
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="1000px"
  >
    <span slot="title">通讯录</span>
    <div class="mail-list">
      <div class="left-wrapper">
        <div class="compony-tab">
          <div v-for="tab in tabs" @click="changeTab(tab)" :key="tab.value" class="tab-item" :class="{'active-item': tab.value === tabType}">
            {{tab.name}}
          </div>
          <div class="active-border" :style="borderStyle"></div>
        </div>
        <!-- <div class="search-box">
          <el-input
            size="medium"
            placeholder="搜索关键字"
            suffix-icon="el-icon-search"
            v-model="filterText">
          </el-input>
        </div> -->
        <en-tree
          ref="tree"
          v-show="tabType === 'compony'"
          @check-change="handleCheckChange"
          check-mode="parent-children"
          :props="props"
          :data="contactData"
          :load="loadNode"
          :render-content="renderContent"
          :filter-node-method="filterNode"
          :lazy="true"
          show-checkbox>
        </en-tree>
        <select-personal-contact v-show="tabType === 'personal'" ref="selectPersonalContact"></select-personal-contact>
      </div>
      <div class="center-wrapper">
        <div style="text-align: center;">
          <en-button type="primary" size="small" icon="el-icon-arrow-right" style="width: 58px;margin-bottom: 12px;" :loading="btnLoading" @click="transferToRight"></en-button>
          <en-button type="white" icon="el-icon-arrow-left" size="small" style="margin-left: 0;width: 58px;" @click="transferToLeft"></en-button>
        </div>
      </div>
      <div class="right-wrapper">
        <ul class="contact-list">
          <li v-for="(item, index) in choiceContactList" @click="toggleChecked(item)" :key="item.id" class="contact-item" :class="{'active-item': item.checked}">
            <div class="contact-name">
              <el-avatar :size="26" style="margin-right: 10px;" :style="{background: bgColor(index)}"> {{item.name[0]}} </el-avatar>
              <span>{{item.name}}</span>
            </div>
            <div class="address">{{item.address}}</div>
          </li>
        </ul>
      </div>
    </div>
    <span slot="footer">
      <en-button type="primary" style="font-size: 12px;" size="medium" @click="confirmSelect">确 定</en-button>
    </span>
  </el-dialog>
</template>

<script>
import { mailSendService } from "@/api/mailBox/mailSend";
import { removeRepeatByKey } from "../../assets/js/utils";
import SelectPersonalContact from "./SelectPersonalContact.vue";

export default {
  name: "MailListDialog",
  components: {
    SelectPersonalContact
  },
  data() {
    return {
      btnLoading: false,
      dialogVisible: false,
      checkedNodes: [],
      halfCheckedNodes: [],
      // 通讯录类型
      tabType: "compony",
      // 穿梭到右侧的联系人
      choiceContactList: [],
      tabs: [
        {
          name: "公司通讯录",
          value: "compony"
        },
        {
          name: "个人通讯录",
          value: "personal"
        }
      ],
      filterText: "",
      props: {
        label: "name",
        children: "nodes",
        isLeaf: "leaf"
      },
      contactData: []
    };
  },
  computed: {
    checkedLeafNodes() {
      return this.checkedNodes.filter((node) => node.leaf);
    },
    borderStyle() {
      if (this.tabType === "compony") {
        return {
          left: "50px"
        };
      }
      return {
        left: "270px"
      };
    }
  },
  mounted() {
    // this.queryObjectTreeExtAccess();
  },
  methods: {
    renderContent(h, { node, data }) {
      if (!data.leaf) {
        return h("div", { style: `color: ${node.checked ? "#3e90fe;" : "#636c78;"}  font-size: 12px;`, attrs: { title: data.name } }, data.name);
      }
      return h("div", { style: `color: ${node.checked ? "#3e90fe;" : "#636c78;"}  font-size: 12px;` }, `${data.name} (${data.address})`);
    },
    bgColor(index) {
      const color = ["#3e90fe", "#26c393", "#ffad2c", "#f76b6b"];
      return color[index % 4];
    },
    changeTab(tab) {
      this.filterText = "";
      this.$nextTick(() => {
        this.tabType = tab.value;
      });
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
      this.getCheckedNodes();
    },
    toggleChecked(item) {
      this.$set(item, "checked", !item.checked);
    },
    async getChildNodes(nodes) {
      const promises = [];
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        promises.push(mailSendService.searchCompanyContact({
          id: node.id,
          levelCode: node.levelCode,
          inAll: true,
          pageNo: 1,
          pageSize: 1000
        }));
      }
      const res = await Promise.all(promises);
      return Promise.resolve(res.reduce((acc, item) => {
        acc.push(...item.records);
        return acc;
      }, []));
    },
    async transferToRight() {
      if (this.tabType === "compony") {
        const rootNodes = this.checkedNodes.filter((item) => item.parentId === "0");
        const childNodes = this.checkedNodes.filter((item) => item.parentId !== "0");
        const pureNodes = [
          ...rootNodes,
          ...childNodes.filter((child) => rootNodes.find((root) => child.parentId === root.id)),
          ...childNodes.filter((child) => this.halfCheckedNodes.find((root) => child.parentId === root.id))
        ];
        this.btnLoading = true;
        const res = await this.getChildNodes(pureNodes);
        this.btnLoading = false;
        this.choiceContactList = removeRepeatByKey([...this.choiceContactList, ...this.checkedLeafNodes, ...res]);
      } else {
        this.btnLoading = true;
        const res = await this.$refs.selectPersonalContact.getChildNodes();
        this.btnLoading = false;
        this.choiceContactList = removeRepeatByKey([...this.choiceContactList, ...res]);
      }
    },
    transferToLeft() {
      this.choiceContactList = this.choiceContactList.filter((item) => !item.checked);
    },
    getCheckedNodes() {
      this.checkedNodes = this.$refs.tree.getCheckedNodes(false);
      this.halfCheckedNodes = this.$refs.tree.getHalfCheckedNodes();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    async queryObjectTreeExtAccess() {
      const res = await mailSendService.queryObjectTreeExtAccess({ objectDataType: "001" });
      this.contactData = res;
    },
    async loadNode(node, resolve) {
      if (node.level === 0) {
        const res = await mailSendService.queryObjectTreeExtAccess({ objectDataType: "001" });
        resolve(res);
        return;
      }
      const res = await mailSendService.searchCompanyContact({
        id: node.data.id,
        levelCode: node.data.levelCode,
        inAll: false,
        pageNo: 1,
        pageSize: 1000
      });
      res.records.forEach((item) => {
        item.leaf = true;
      });
      resolve([...(node.data.nodes || []), ...res.records]);
    },
    show() {
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },
    confirmSelect() {
      this.hide();
      this.$emit("confirmSelect", this.choiceContactList);
    }
  },
  watch: {
    filterText(val) {
      if (this.tabType === "compony") {
        this.$refs.tree.filter(val);
      } else {
        this.$refs.selectPersonalContact.filter(val);
      }
    }
  }
};
</script>
<style lang="scss">
.mail-list-dialog {
  .el-dialog__header {
    line-height: 1;
  }
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 0;
  }
}
</style>
<style lang="scss" scoped>
.mail-list-dialog {
  .mail-list {
    display: flex;
    height: 440px;
    .left-wrapper, .right-wrapper {
      height: 100%;
      width: 440px;
      box-sizing: border-box;
      border: 1px solid #e8ecf2;
      border-radius: 4px;
      overflow-y: auto;
    }
    .left-wrapper {
      .search-box {
        padding: 10px;
      }
      .compony-tab {
        position: relative;
        display: flex;
        height: 40px;
        font-size: 13px;
        border-bottom: 1px solid #e8ecf2;
        :first-child {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 8px;
            right: 0;
            height: 24px;
            width: 1px;
            background: #e8ecf2;
          }
        }
        .tab-item {
          width: 50%;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
        }
        .active-item {
          color: #3e90fe;
          font-weight: bold;
        }
        .active-border {
          position: absolute;
          bottom: -1px;
          width: 120px;
          height: 2px;
          border-radius: 2px;
          background: #3e90fe;
          transition: all .5s;
        }
      }
    }
    .right-wrapper {
      .contact-list {
        padding: 0;
        margin: 0;
        .contact-item {
          display: flex;
          height: 40px;
          padding-left: 20px;
          font-size: 12px;
          align-items: center;
          cursor: pointer;
          color: #636c78;
          // &:hover {
          //   background: #f5f8fc;
          // }
          // .address {
          //   color: #3e90fe;
          // }
          &.active-item {
            background: #f5f8fc;
            color: #3e90fe;
          }
          .contact-name {
            width: 180px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .center-wrapper {
      width: 84px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
