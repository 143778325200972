<template>
  <en-tree
    ref="tree"
    @check-change="handleCheckChange"
    check-mode="parent-children"
    :props="props"
    :data="contactData"
    :load="loadNode"
    :render-content="renderContent"
    :filter-node-method="filterNode"
    :lazy="true"
    show-checkbox>
  </en-tree>
</template>

<script>
import { mailSendService } from "@/api/mailBox/mailSend";

export default {
  name: "SelectPersonalContact",
  data() {
    return {
      contactData: [],
      checkedNodes: [],
      props: {
        label: "name",
        children: "nodes",
        isLeaf: "leaf"
      }
    };
  },
  methods: {
    renderContent(h, { node, data }) {
      if (!data.leaf) {
        return h("div", { style: `color: ${node.checked ? "#3e90fe;" : "#636c78;"}  font-size: 12px;`, attrs: { title: data.name } }, data.name);
      }
      return h("div", { style: `color: ${node.checked ? "#3e90fe;" : "#636c78;"}  font-size: 12px;` }, `${data.name} (${data.address})`);
    },
    async getChildNodes() {
      const nodes = this.checkedNodes.filter((item) => !item.leaf);
      const promises = [];
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        promises.push(mailSendService.getContactList({
          classRefId: node.id,
          id: node.id,
          pageNo: 1,
          pageSize: 1000
        }));
      }
      const res = await Promise.all(promises);
      return Promise.resolve(res.reduce((acc, item) => {
        acc.push(...item.records);
        return acc;
      }, []));
    },
    handleCheckChange() {
      this.checkedNodes = this.$refs.tree.getCheckedNodes();
      console.log(this.checkedNodes);
    },
    async loadNode(node, resolve) {
      console.log(node);
      if (node.level === 0) {
        const res = await mailSendService.getContactClassifies();
        console.log(res);
        resolve(res);
        return;
      }
      node.isOpen = true;
      const res = await mailSendService.getContactList({
        classRefId: node.data.id,
        id: node.data.id,
        pageNo: 1,
        pageSize: 1000
      });
      console.log(res);
      res.records.forEach((item) => {
        item.leaf = true;
      });
      resolve(res.records);
    },
    filter(val) {
      this.$refs.tree.filter(val);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
