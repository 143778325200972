var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        "custom-class": "mail-list-dialog",
        "append-to-body": "",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("通讯录"),
      ]),
      _c("div", { staticClass: "mail-list" }, [
        _c(
          "div",
          { staticClass: "left-wrapper" },
          [
            _c(
              "div",
              { staticClass: "compony-tab" },
              [
                _vm._l(_vm.tabs, function (tab) {
                  return _c(
                    "div",
                    {
                      key: tab.value,
                      staticClass: "tab-item",
                      class: { "active-item": tab.value === _vm.tabType },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(tab)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(tab.name) + " ")]
                  )
                }),
                _c("div", {
                  staticClass: "active-border",
                  style: _vm.borderStyle,
                }),
              ],
              2
            ),
            _c("en-tree", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabType === "compony",
                  expression: "tabType === 'compony'",
                },
              ],
              ref: "tree",
              attrs: {
                "check-mode": "parent-children",
                props: _vm.props,
                data: _vm.contactData,
                load: _vm.loadNode,
                "render-content": _vm.renderContent,
                "filter-node-method": _vm.filterNode,
                lazy: true,
                "show-checkbox": "",
              },
              on: { "check-change": _vm.handleCheckChange },
            }),
            _c("select-personal-contact", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabType === "personal",
                  expression: "tabType === 'personal'",
                },
              ],
              ref: "selectPersonalContact",
            }),
          ],
          1
        ),
        _c("div", { staticClass: "center-wrapper" }, [
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c("en-button", {
                staticStyle: { width: "58px", "margin-bottom": "12px" },
                attrs: {
                  type: "primary",
                  size: "small",
                  icon: "el-icon-arrow-right",
                  loading: _vm.btnLoading,
                },
                on: { click: _vm.transferToRight },
              }),
              _c("en-button", {
                staticStyle: { "margin-left": "0", width: "58px" },
                attrs: {
                  type: "white",
                  icon: "el-icon-arrow-left",
                  size: "small",
                },
                on: { click: _vm.transferToLeft },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "right-wrapper" }, [
          _c(
            "ul",
            { staticClass: "contact-list" },
            _vm._l(_vm.choiceContactList, function (item, index) {
              return _c(
                "li",
                {
                  key: item.id,
                  staticClass: "contact-item",
                  class: { "active-item": item.checked },
                  on: {
                    click: function ($event) {
                      return _vm.toggleChecked(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "contact-name" },
                    [
                      _c(
                        "el-avatar",
                        {
                          staticStyle: { "margin-right": "10px" },
                          style: { background: _vm.bgColor(index) },
                          attrs: { size: 26 },
                        },
                        [_vm._v(" " + _vm._s(item.name[0]) + " ")]
                      ),
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "address" }, [
                    _vm._v(_vm._s(item.address)),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "en-button",
            {
              staticStyle: { "font-size": "12px" },
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.confirmSelect },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }