var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("en-tree", {
    ref: "tree",
    attrs: {
      "check-mode": "parent-children",
      props: _vm.props,
      data: _vm.contactData,
      load: _vm.loadNode,
      "render-content": _vm.renderContent,
      "filter-node-method": _vm.filterNode,
      lazy: true,
      "show-checkbox": "",
    },
    on: { "check-change": _vm.handleCheckChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }